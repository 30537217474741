<template>
  <div>
    <title-bar :title="$t('navigations.navigation')" />
    <div v-if="this.rows" class="custom-table">
      <table-actions
        :actions="['addBtn', 'perPage']"
        :addBtnText="$t('messages.navigationNew')"
        :addBtnLink="$helper.getAddUrl('navigation')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
    
        <template v-slot:position="data">
          <strong>{{
            data.value.position
          }}</strong>
        </template>
       
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('navigation', data.value.position)" />
            <delete-btn @pressDelete="deleteNavigation(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";

import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    TableLite,
    TablePagination,
    EditBtn,
    DeleteBtn,
    TitleBar,
    TableActions
  },
  data() {
    return {
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t('forms.position'),
          field: "position",
          sortable: false,
        },
        {
          label: this.$t('forms.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadNavigations();
  },
  methods: {
    loadNavigations() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      this.$Navigations.getCollection({ params }).then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadNavigations();
    },
    deleteNavigation(url) {
      this.$Navigations
        .deleteResourceByUrl({ url })
        .then((response) => {
          if (response.status === 204) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              this.$t('messages.navigationDeleted'),
              "",
              "success"
            );
            this.loadNavigations();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$emit("clearAction");
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    onPageChange() {
      this.loadNavigations();
    },
  },
}
</script>
